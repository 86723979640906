import { getAxiosPrivate } from "./common";

const fileManagerRequestUrl = "/file/httpdownloader/"

const url = {
    getSources: (store) => getAxiosPrivate(store).get('/sources/'),
}

export const fileManagerRequest = async (store) => {
    return getAxiosPrivate(store)
        .get(`${fileManagerRequestUrl}`)
}
export const actionSourcesRequest = async (store, type, id, data) => {
    return url[type](store, { id, data })
}
