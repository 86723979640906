import {getAxiosPrivate, getAxiosPublic} from "./common";

const dashboardRequestURL = "/panel/"
const dashboardRequestURLPublic = "/public/panel"
const dashboardPanelsRequestURL = "/dashboard/"
const dashboardPanelsRequestURLPrivate = "/private/dashboard/"
const dashboardPanelsRequestURLPublic = "/public/dashboard/"

export const dashboardDataRequest = async (panel, store) => {
    return getAxiosPrivate(store)
        .get(`${dashboardRequestURL}?id=${panel.id}&chart_type=${panel.type}`)
}

export const dashboardDataRequestPublic = async (panel, store) => {
    return getAxiosPublic(store)
        .get(`${dashboardRequestURLPublic}?id=${panel.id}&chart_type=${panel.type}`)
}

export const dashboardPanelsRequest = async (store) => {
    return getAxiosPrivate(store)
        .get(dashboardPanelsRequestURL)
}

export const dashboardPanelsRequestPrivate = async (store) => {
    return getAxiosPrivate(store)
        .get(dashboardPanelsRequestURLPrivate)
}

export const dashboardPanelsRequestPublic = async (store) => {
    return getAxiosPublic(store)
        .get(dashboardPanelsRequestURLPublic)
}