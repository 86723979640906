import {Add, Information} from "@carbon/icons-react";
import {Button} from "@carbon/react";
import {useDispatch} from "react-redux";
import {toggleModal} from "../../../store/reducers/modalsReducer.js";

const Index = ({chart, modalId}) => {
    const dispatch = useDispatch();
    const title = chart.options.title
    const subtitle = chart.options.subtitle
    const description = chart.options.description
    const user_description = chart.options.user_description
    let tooltip = ''
    if (chart.options.tooltip || chart.options.user_description) {
        if (chart.options.subtitle) {
            tooltip = <>
                <Button
                    kind={'ghost'}
                    hasIconOnly={true}
                    renderIcon={Information}
                    iconDescription={'Más información'}
                    onClick={() => dispatch(toggleModal(modalId))}>

                </Button>
            </>
        } else {
            tooltip = <>
                <Button
                    className={'info-action'}
                    kind={'tertiary'}
                    onClick={() => dispatch(toggleModal(modalId))}>
                    <Add size={18}/>
                    Información
                </Button>
            </>
        }
    }
    let user_desc = <></>
    if (user_description && user_description !== '')
        user_desc = <>
            <div className={'panel-title-container top-border'}>
                <h5 className="cds--cc--title">Notas del usuario</h5>

            </div>
            {user_description}
        </>
    const default_type = <>
        <br></br>
        <div className="panel-description">{description}</div>
        <div className={'panel-title-container top-border'}>
            {tooltip}
        </div>
        {/*{user_desc}*/}
    </>
    const table_type = <>
        <div className="cds--data-table-header">
            <div className={'panel-title-container'}>
                <h4 className="cds--data-table-header__title"
                    id="tc-46-title">{title}</h4>
                {tooltip}
            </div>
            <h5 className="cds--data-table-header__title">{subtitle}</h5>
            <div className="panel-description">{description}</div>
        </div>
    </>

    const descriptionComponent = (<div className={'chart-description'}>
        {chart.type !== 'table_chart' ? default_type : table_type}
    </div>)
    return (
        <>{descriptionComponent}</>
    )

}


export default Index
