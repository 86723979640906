const toolbar = {
    enabled: true,
    controls: [
        {
            type: "Export as CSV",
            text: 'Exportar como CSV'
        },
        {
            type: "Export as PNG",
            text: 'Exportar como PNG'
        },
        {
            type: "Export as JPG",
            text: 'Exportar como JPG'
        },
        {
            type: "Zoom in",
        },
        {
            type: "Zoom out",
        },
        {
            type: "Reset zoom",
        },
        {
            type: "Make fullscreen",
        },
        {
            type: "Show as data-table",
        }
    ]
}

const toolbarMaps = {
    enabled: true,
    controls: [
        {
            type: "Zoom in",
        },
        {
            type: "Zoom out",
        },
        {
            type: "Reset zoom",
        },
        {
            type: "Make fullscreen",
        },
    ]
}
const legend = {
    //alignment: 'center',
    truncation: {
      type: 'none',
    },
    //enabled : false,
}
const barDefaultOptions =
    {
        title: "",
        axes: {
            left: {
                mapsTo: "value",
                scaleType: "linear"
            },
            bottom: {
                mapsTo: "group",
                scaleType: "labels"
            }
        },
        height: '30rem',
        toolbar,
        legend,
    }

const groupedBarDefaultOptions =
    {
        title: "",
        axes: {
            left: {
                mapsTo: "value",
                scaleType: "linear",
            },
            bottom: {
                mapsTo: "key",
                scaleType: "labels",
            }
        },
        height: '30rem',
        toolbar,
        legend
    }
const stackedBarDefaultOptions =
    {
        title: "",
        axes: {
            left: {
                mapsTo: "value",
                scaleType: "linear",
                stacked: true
            },
            bottom: {
                mapsTo: "key",
                scaleType: "labels",
            }
        },
        height: '30rem',
        toolbar,
        legend
    }
const lineDefaultOptions = {
    title: "",
    axes: {
        bottom: {
            mapsTo: "key",
            scaleType: "time",
            includeZero: false
        },
        left: {
            mapsTo: "value",
            scaleType: "linear",
            includeZero : false
        }
    },
    timeScale: {
        addSpaceOnEdges: 0
    },
    height: "30rem",
    toolbar,
    
    curve: "curveMonotoneX",
    legend

}
const areaDefaultOptions = {
    title: "",
    axes: {
        left: {
            stacked: true,
            scaleType: "linear",
            mapsTo: "value"
        },
        bottom: {
            scaleType: "labels",
            mapsTo: "key"
        }
    },
    curve: "curveMonotoneX",
    height: "30rem",
    toolbar,
    legend
}
const histogramDefaultOptions = {
    title: "",
    resizable: true,
    height: "30rem",
    axes: {
        bottom: {
            mapsTo: "value",
            bins: 10,
            limitDomainToBins: true
        },
        left: {
            scaleType: "linear",
            stacked: true,
            binned: true
        }
    },
    toolbar,
    legend

}
const heatMapDefaultOptions = {
    title: "",
    resizable: true,
    height: "30rem",
    heatmap: {
        colorLegend: {
            title: "Legend title"
        }
    },
    experimental: true,
    axes: {
        bottom: {
            scaleType: "labels",
            mapsTo: "key",
        },
        left: {
            scaleType: "linear",
            mapsTo: "value",
        }
    },
    toolbar,
    legend

}
const sparklineDefaultOptions = {
    title: "",
    resizable: true,
    height: "30rem",
    axes: {
        bottom: {
            visible: false,
            scaleType: "labels",
            mapsTo: "key",
        },
        left: {
            visible: false,
            scaleType: "linear",
            mapsTo: "value"
        }
    },
    grid: {
        x: {
            enabled: false
        },
        y: {
            enabled: false
        }
    },
    color: {
        gradient: {
            enabled: true
        }
    },
    points: {
        enabled: false
    },
    legend: {
        ...legend, ...{enabled: false}
    },
    toolbar,

}
const lollipopDefaultOptions = {
    title: "",
    resizable: true,
    height: "30rem",
    axes: {
        bottom: {
            scaleType: "labels",
            mapsTo: "key",
        },
        left: {
            mapsTo: "value",
        }
    },
    toolbar,
    legend

}
const scatterDefaultOptions = {
    title: "",
    resizable: true,
    height: "30rem",
    axes: {
        bottom: {
            scaleType: "labels",
            mapsTo: "key",
        },
        left: {
            scaleType: "linear",
            mapsTo: "value"
        }
    },
    toolbar,
    legend
}
const bubbleDefaultOptions = {
    title: "",
    resizable: true,
    height: "30rem",
    axes: {
        bottom: {
            scaleType: "linear",
            mapsTo: "key",
            includeZero: false
        },
        left: {
            scaleType: "linear",
            mapsTo: "value",
            includeZero: false
        }
    },
    bubble: {
        radiusMapsTo: "key",
        radiusLabel: "key"
    },
    toolbar,
    legend
}
const pieDefaultOptions = {
    title: "",
    resizable: true,
    height: "30rem",
    pie: {
        alignment: "center"
    },
    toolbar,
    legend

}
const gaugeDefaultOptions = {
    title: "",
    resizable: true,
    height: "30rem",
    gauge: {
        type: "semi",
        status: "danger"
    },
    toolbar,
    legend

}
const donutDefaultOptions = {
    title: "",
    resizable: true,
    height: "30rem",
    donut: {
        center: {
            label: ''
        },
        alignment: "center"
    },
    toolbar,
    legend

}
const meterProportionalDefaultOptions = {
    title: "",
    height: "30rem",
    meter: {
        proportional: {
            total: 0,
            // unit: "GB"
        }
    },
    color: {
        pairing: {
            option: 2
        }
    },
    toolbar,
    legend
}
const boxplotDefaultOptions = {
    title: "",
    height: "30rem",
    axes: {
        bottom: {
            scaleType: "linear",
            mapsTo: "value",
        },
        left: {
            scaleType: "labels",
            mapsTo: "group"
        }
    },
    toolbar,
    legend
}
const experimentalChoroplethDefaultOptions = { 
    title: "",
    geoData: {},
    color: {
        pairing: {
            option: 2
        }
    },
    height: '60rem',
    experimental: true,
    resizable: true,
    toolbar: toolbarMaps
}
export {
    pieDefaultOptions,
    areaDefaultOptions,
    barDefaultOptions,
    groupedBarDefaultOptions,
    stackedBarDefaultOptions,
    lineDefaultOptions,
    histogramDefaultOptions,
    heatMapDefaultOptions,
    gaugeDefaultOptions,
    lollipopDefaultOptions,
    scatterDefaultOptions,
    sparklineDefaultOptions,
    meterProportionalDefaultOptions,
    boxplotDefaultOptions,
    donutDefaultOptions,
    bubbleDefaultOptions,
    experimentalChoroplethDefaultOptions
}
