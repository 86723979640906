import { Logout, User, Login} from "@carbon/icons-react";
import {
    Button,
    ComposedModal,
    Header,
    HeaderContainer,
    HeaderGlobalAction,
    HeaderGlobalBar,
    HeaderName,
    ModalBody,
    ModalFooter,
    SkipToContent,
} from '@carbon/react';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ModalStateManager from "../../components/Modal/index.js";
import { addModal, closeModal, toggleModal } from "../../store/reducers/modalsReducer.js";
import { isAdminThunk, logout, usernameRequestThunk } from "../../store/reducers/userReducer"
import { getModalById } from "../../Utils/utils.js";

const default_urls = []
const HeaderLayout = () => {
    const navigator = useNavigate();
    const location = useLocation()
    const dispatch = useDispatch()
    const dispatchLogout = () => {
        dispatch(closeModal(modalLogout))
        dispatch(logout())
    }
    const user = useSelector((state) => state.user)
    const modal = useSelector((state) => state.modals)
    const modalLogout = 'logout'
    const username = user.username

    const [navItems, setNavItem] = useState([...default_urls])

    useEffect(() => {
        dispatch(addModal(modalLogout))
    }, [modal])
    useEffect(() => {
        if (!user.isAdmin) {
            dispatch(isAdminThunk()).unwrap().then((e) => {
                try{
                    let new_urls = [...navItems]
                    new_urls[new_urls.length - 1].active = e.is_admin
                    setNavItem(() => ([...new_urls]))
                } catch (error) {}
            })
        }
    }, [])
    

    const modalService = ModalStateManager(
        {
            children:
                <>
                    <ComposedModal
                        open={getModalById(modal, modalLogout) ? getModalById(modal, modalLogout).state : false}
                        onClose={() => dispatch(closeModal(modalLogout))} size="md">
                        <ModalBody style={{ backgroundColor: '#E8E8E8' }}>
                            <h2 >Are you sure you want to leave the app?</h2>
                            <br />
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                kind="danger"
                                onClick={() =>
                                    dispatch(closeModal(modalLogout))
                                }>
                                Cancel
                            </Button>
                            <Button
                                kind="primary"
                                onClick={() =>
                                    dispatchLogout()
                                }>
                                Exit
                            </Button>
                        </ModalFooter>
                    </ComposedModal>
                </>
        }
    )
    let headerNavItems = <></>
    let sideNav = <></>
    let headerGlobal = <></>
    if (location.pathname == '/public/dashboard') {
        headerGlobal = <HeaderGlobalBar>
                            <HeaderGlobalAction aria-label="Login" onClick={() => { navigator('/login') }}>
                                <Login />
                            </HeaderGlobalAction>
                        </HeaderGlobalBar>
        return (
            <HeaderContainer
                render={({ isSideNavExpanded, onClickSideNavExpand }) => (
                    <Header aria-label="Ecotours">
                        <SkipToContent />
                        <HeaderName className="pointer" onClick={() => {
                            navigator('/public/dashboard')
                        }} prefix="Ecotours">
                            <span className={'subtitle'}>Project</span>
                        </HeaderName>
                        {headerNavItems}
                        {headerGlobal}
                        {modalService}
                    </Header>)}
            />
            )
    }
    else if (location.pathname !== '/' && location.pathname !== '/login') {
        headerGlobal = <HeaderGlobalBar>
            {username ? (
                <span className="username"> Hello, {username}</span>
            ) : (
                <span className="username"> Hello</span>
            )}      
            <HeaderGlobalAction aria-label="Exit"
                onClick={() => dispatch(toggleModal(modalLogout))}><Logout />
            </HeaderGlobalAction>
        </HeaderGlobalBar>
    return (
        <HeaderContainer
            render={({ isSideNavExpanded, onClickSideNavExpand }) => (
                <Header aria-label="Ecotours">
                    <SkipToContent />
                    <HeaderName className="pointer" onClick={() => {
                        navigator('/dashboard')
                    }} prefix="Ecotours">
                        <span className={'subtitle'}>Project</span>
                    </HeaderName>
                    {headerNavItems}
                    {headerGlobal}
                    {modalService}
                </Header>)}
        />
        )
    }
    else {
        return (
            <HeaderContainer
                render={({ }) => (
                    <Header aria-label="Ecotours">
                        <SkipToContent />
                        <HeaderName className="pointer" onClick={() => {
                            navigator('/login')
                        }} prefix="Ecotours">
                            <span className={'subtitle'}>Project</span>
                        </HeaderName>
                    </Header>)}
            />
        )
    }
}
export default HeaderLayout
