import aniko from './aniko-logo.png'; // with import
import cscm from './CSCSMKIK-logo.png';
import enoros from './enoros-logo.png';
import fc from './fc-logo.png';
import petra from './petra-logo.png';
import prism from './prism-logo.png';
import uvigo from './uvigo-logo.png';
import ue from './UE-logo.png';
import logo from './logo-light.svg';

import { Map, Email, Phone, LogoLinkedin, LogoYoutube, LogoTwitter, LogoFacebook } from "@carbon/icons-react";

const FooterLayout = () => (
    <footer>
        <div className="footer-big-block">
            <div className="inner-footer">
                <div className="footer-big-inner-block">
                    <div className="footer-element-general">
                        <section>
                            <div>
                                <a href="https://ecotours-project.eu/" target="_blank"><img className="img-footer-logo" src={logo} /></a>
                            </div>
                        </section>
                    </div>
                    <div className="footer-element-general">
                        <section>
                            <h2>Contact</h2>
                            <br />
                            <div>
                                <p><Phone />   +34 986 812 000 </p>
                                <p><Email /><a href="mailto:gladys.otero@espossible.com">   gladys.otero@espossible.com</a></p>
                            </div>
                        </section>
                    </div>
                    <div className="footer-element-general">
                        <section>
                            <br />
                            <div>
                                <p class="footer-copyright">Empowering local communities turning them into laboratories for co-development of circular and sustainable tourism ecosystems.</p>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
        <div className="footer-block-1">
            <div className="inner-footer">
                <div className="footer-block-2">
                    <div className="footer-element-general">
                        <a href="https://www.prismonline.eu/">
                            <img src={prism} />
                        </a>
                    </div>
                    <div className="footer-element-general">
                        <a href="https://www.uvigo.gal/">
                            <img src={uvigo} />
                        </a>
                    </div>
                    <div className="footer-element-general">
                        <a href="https://www.petrapatrimonia-corse.com/">
                            <img src={petra} />
                        </a>
                    </div>
                    <div className="footer-element-general">
                        <a href="http://enoros.com.cy/">
                            <img src={enoros} />
                        </a>
                    </div>
                    <div className="footer-element-general">
                        <a href="https://fcagrigentotrapani.it/">
                            <img src={fc} />
                        </a>
                    </div>
                    <div className="footer-element-general">
                        <a href="https://www.anko.gr/index.php/en/">
                            <img src={aniko} />
                        </a>
                    </div>
                    <div className="footer-element-general">
                        <a href="https://csmkik.hu/">
                            <img src={cscm} />
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div className="footer-block-1">
            <div className="inner-footer">
                <div className="footer-block-3">
                    <div className="footer-element-general">
                        <img src={ue} />
                    </div>
                    <div className="footer-element-general">
                        <p>Funded by the European Union. Views and opinions expressed are however those of the author(s) only and do not necessarily 
                           reflect those of the European Union or the European Innovation Council and SMEs Executive Agency (EISMEA). Neither the European Union 
                           nor EISMEA can be held responsible for them.</p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
)
export default FooterLayout
